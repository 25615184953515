import React from "react"
import Layout from "../../../Components/pageComponents/contentContainer"
// import Image from "../../../Components/pageComponents/carouselCard"
import SolutionInfoSection from "../../../Components/pageComponents/solutionsInfoSection"


export const query = graphql`
    query {
        banner: file(relativePath: { eq: "products/software-and-applications/Software & Applications.png" }) {
            childImageSharp {
                fluid(maxWidth: 2000) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        adobe: file(relativePath: { eq: "products/software-and-applications/Adobe Stock.jpg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        microsoft: file(relativePath: { eq: "products/software-and-applications/Microsoft Office Stock.png" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
      
`
export default ({ data }) => {
    return (
        <Layout title="Software & Applications" image={data.banner.childImageSharp.fluid}>
            <SolutionInfoSection heading="Products We Offer" title="Software and Applications" description="Be productive with Fastlink, as we offer all kinds of Applications, Software Licenses, and Subscriptions. We support our users from the Pre-Sales, Purchase, and Installation, to the Post-Sales Service."
                img={data.microsoft.childImageSharp.fluid} >
                <ol>
                    <li>Microsoft Office Suite</li>
                    <li>Adobe Creative Suite</li>
                </ol>
            </SolutionInfoSection>

            {/* <section className="px-5 py-3 text-center">
                <h4 className="py-4">Be productive with Fastlink, as we offer all kinds of Applications, Software Licenses, and Subscriptions. We support our users from the Pre-Sales, Purchase, and Installation, to the Post-Sales Service. </h4>
            </section>
            <section className="d-flex flex-wrap align-middle pt-5">
                <div className="text-center d-flex my-auto mx-auto justify-content-center" style={{
                    height: "400px",
                    width: " 300px"
                }}>
                    <Image img={data.adobe.childImageSharp.fluid}>
                        Adobe Suite
                    </Image>
                </div>
                <div className="text-center d-flex my-auto mx-auto justify-content-center" style={{
                    height: "400px",
                    width: " 300px"
                }}>
                    <Image img={data.microsoft.childImageSharp.fluid}>
                        Microsoft Office Tools
                </Image>
                </div> 
            </section>*/}


        </Layout>
    )
}
